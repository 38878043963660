<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="手机号码"
          v-model="query.phone"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="用户姓名"
          v-model="query.realName"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-select placeholder="套餐是否到期" v-model="query.isOver" clearable>
          <el-option label="到期用户" :value="1"></el-option>
          <el-option label="正常用户" :value="0"></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="id"
          v-model="query.id"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="id" prop="id" width="50"></el-table-column>
      <el-table-column label="昵称" prop="nickName">
        <template #default="scope">
          <div>
            <span v-if="scope.row.realName">{{ scope.row.realName }} </span>
            <span v-else>未实名 </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="代理商" prop="operationName"></el-table-column>

      <el-table-column label="代理状态" prop="packStatus">
        <template #default="scope">
          <eb-badge
            :list="this.const.USER_STATUS"
            :target="scope.row.packStatus"
            v-if="scope.row.operationName"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="手机号" prop="phone" align="right">
      </el-table-column>
      <!-- <el-table-column label="押金状态" prop="levelName"></el-table-column> -->
      <el-table-column label="押金金额" prop="money">
        <template #default="scope">
          <span>{{ util.formatNum(scope.row.money / 100) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="押金类型" prop="depositType">
        <template #default="scope">
          <eb-badge
            :list="this.const.DEPOSIT_TYPE"
            :target="scope.row.depositType"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="套餐到期时间" prop="endTime">
        <template #default="scope">
          <span v-if="!validatenull(scope.row.endTime)">{{
            this.$filter.dateFormat(new Date(scope.row.endTime))
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="套餐剩余时间" prop="endTime">
        <template #default="scope">
          <span v-if="!validatenull(scope.row.endTime)">{{
            this.util.formatDay(
              (new Date(scope.row.endTime) - new Date()) / 1000
            )
          }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="使用状态" prop="account"></el-table-column> -->
      <el-table-column label="实名认证" prop="hasVerify">
        <template #default="scope">
          <eb-badge
            :list="this.const.HAS_VERIFY"
            :target="scope.row.hasVerify"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="积分" prop="score"></el-table-column>

      <el-table-column label="邀请人" prop="inviteName">
        <template #default="scope">
          <span> {{ scope.row.inviteRealName || "自然人" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="用户类型" prop="isPusher">
        <template #default="scope">
          <eb-badge
            :list="this.const.PUSHER_USER_TYPE"
            :target="scope.row.isPusher"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="注册时间" prop="createAt">
        <template #default="scope">
          <span>
            {{ this.$filter.dateFormat(new Date(scope.row.createAt)) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="备注" prop="remark"></el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            v-auth="this.per.SHOW_USER_DETAIL"
            @click="doDetail(scope.row.id, false)"
            >详情</span
          >

          <el-dropdown>
            <span class="option option-primary">
              更多 <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="showEditPusherType(scope.row)">
                  <span>修改推广类型</span>
                </el-dropdown-item>

                <el-dropdown-item @click="showEdit(scope.row)">
                  <span>修改套餐</span>
                </el-dropdown-item>

                <el-dropdown-item @click="doPause(scope.row)"
                  >暂停套餐</el-dropdown-item
                >

                <el-dropdown-item @click="showExchangeBindWx(scope.row)">
                  微信换绑
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submitEndTime"
    ></EditModal>

    <EditUserPusherTypeModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.ADD_PUSHER_MODAL"
      @submit="submitAddPusher"
    ></EditUserPusherTypeModal>

    <InviterModal
      :modalData="modalData"
      :ref="MODAL_KEY.INVITER_MODAL"
      @submit="inviterSubmit"
    ></InviterModal>

    <ExchangeBindWxModal
      :modalData="modalData"
      @submit="doExchangeBindWx"
      :ref="MODAL_KEY.EXCHANGE_BIND"
    ></ExchangeBindWxModal>
  </div>
</template>


<script>
import {
  fetchBatteryManList,
  updateUserInfo,
  updateUserPusherType,
  updateUser,
  pauseProduct,
  exchangeBindWx
} from "@/api/batteryMan";

import moment from "moment";

import { mapState } from "vuex";
import EditModal from "./components/EditModal";
import EditUserPusherTypeModal from "./components/EditUserPusherTypeModal";
import InviterModal from "./components/inviterModal";
import ExchangeBindWxModal from "./components/ExchangeBindWxModal";

export default {
  components: { EditModal, EditUserPusherTypeModal, InviterModal ,ExchangeBindWxModal},
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    return {
      dataSource: [],
      modalData: {},
      pages: {},
      endTime: "",
      expire: -1,
      query: {
        startTime: "",
        endTime: "",
      },
      modalType: "add",
      MODAL_KEY: {
        EXCHANGE_BIND:"EXCHANGE_BIND",
        ADD_PUSHER_MODAL: "ADD_PUSHER_MODAL",
        EDIT_MODAL: "EDIT_MODAL",
        INVITER_MODAL: "INVITER_MODAL",
      },
      total: 0,
    };
  },

  beforeRouteEnter(to, from, next) {
    if (from.fullPath.indexOf("detail") > -1) {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },

  activated() {
    if (!this.$route.meta.isBack || this.dataSource.length === 0) {
      this.resetPage();
      this.$store.dispatch("fetchAgentListAll").then((res) => {
        this.getList(1);
      });
    }
  },

  methods: {
    showExchangeBindWx(){
      this.modalData = {};
      this.$refs[this.MODAL_KEY.EXCHANGE_BIND].showModal();
    },
    // 微信换绑
    doExchangeBindWx(data){
      exchangeBindWx(data).then(res=>{
        this.$message.success("换绑成功！");
        this.$refs[this.MODAL_KEY.EXCHANGE_BIND].closeModal();
      })
    },
    showInviterModal(row) {
      this.modalData = row;
      this.$refs[this.MODAL_KEY.INVITER_MODAL].showModal();
    },
    inviterSubmit(res) {
      this.getList();
      this.$refs[this.MODAL_KEY.INVITER_MODAL].closeModal();
    },
    doPause(data) {
      this.$confirm("是否要冻结用户套餐", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        pauseProduct({
          userId: data.id,
          reason: "管理员冻结套餐",
        }).then((res) => {
          this.getList();
        });
      });
    },

    changeDate(type) {
      let curDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (type == 2) {
        this.query.startTime = curDate;
        this.query.endTime = "";
      } else if (type == 1) {
        this.query.endTime = curDate;
        this.query.startTime = "";
      } else {
        this.query.endTime = "";
        this.query.startTime = "";
      }
      this.expire = -1;
      this.getList(1);
    },

    showEditPusherType(data) {
      this.modalType = "edit";
      this.modalData = {
        isPusher: data.isPusher,
        realName: data.realName,
        userId: data.id,
      };
      console.log(this.$refs);
      this.$refs[this.MODAL_KEY.ADD_PUSHER_MODAL].showModal();
    },

    // 增加推广员
    submitAddPusher(data) {
      updateUserPusherType({
        userId: data.userId,
        isPusher: data.isPusher,
        description: data.remark,
      }).then((res) => {
        this.$refs[this.MODAL_KEY.ADD_PUSHER_MODAL].closeModal();
        this.getList();
      });
    },

    showEdit(data) {
      this.modalData = {
        userId: data.id,
        realName: data.realName,
        endTime: data.endTime,
        phone: data.phone,
        openId: data.openid,
        remark: data.remark,
      };
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    submitUser(data) {
      updateUserInfo(data).then((res) => {
        this.$message.success("操作成功");
        this.getList();
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
      });
    },

    submitEndTime(data) {
      updateUser(data).then((res) => {
        this.$message.success("操作成功");
        this.getList();
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
      });
    },

    // settingPresenter(data, type) {
    //   this.$confirm("是否要修改推广员？", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(confrim=>{
    //     updatePusher({
    //     userId: data.id,
    //     isPusher: type,
    //   }).then((res) => {
    //     this.getList();
    //     this.$message.success("操作成功");
    //   });
    //   })
    // },
    // 1 到期用户 2 未到期的用户

    // 查询指定时间到期
    changeProductDate(day) {
      this.endTime = "";
      if (day == -1) {
        this.query.endTime = "";
        this.query.startTime = "";
      } else {
        let curDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        let tarDate = moment(new Date())
          .add(day, "d")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = tarDate;
        this.query.startTime = curDate;
      }

      this.getList(1);
    },

    resetPage() {
      this.pages = {
        pageIndex: 1,
        pageSize: 10,
      };
      this.endTime = "";
      this.query = {};
    },

    changeArea(e) {
      console.log(e);
    },
    doDetail(id) {
      this.$router.push("/user/detail/" + id);
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchBatteryManList({ ...this.query, ...this.pages }).then((res) => {
        let list = res.data.data.records || [];
        this.total = res.data.data.total;
        this.dataSource = list;
      });
    },
  },
};
</script>
